<template>
    <div class="page-nav">
        <div class="logo-box">
            <!-- <img class="logo" src="@/assets/images/index/logo.png" @click="to('/')" /> -->
			<div class="system-name" @click="to('/')">{{$systemName}}</div>
        </div>
        <div class="nav-list">
            <template v-for="(item,index) in navList">
                <span v-if="index != 1" class="nav-item" @click="to(item.url)">{{item.name}}</span>
                <el-dropdown v-else class="dropdown-item" placement="bottom" :show-timeout="100" @command="toProducts">
                    <span class="dropdown-title">
                        {{item.name}}<i class="el-icon-caret-bottom el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item :command="0">
                            All
                        </el-dropdown-item>
                        <el-dropdown-item v-for="(item,index) in category" :key="index" :command="item.id">
                            {{item.name}}
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </template>
        </div>
        <div class="menu-box">
            <div class="menu" @click="showMenu = true">
                <img class="icon" src="@/assets/images/index/menu.png" />
                Menu
            </div>
        </div>
        <transition name="slide-right">
            <div v-if="showMenu" class="nav-mask">
                <div class="nav-list">
                    <template v-for="(item,index) in navList">
                        <span v-if="index != 1" class="nav-item" @click="to(item.url)">{{item.name}}</span>
                        <el-dropdown v-else class="dropdown-item" placement="bottom" :show-timeout="100" @command="toProducts">
                            <span class="dropdown-title">
                                {{item.name}}<i class="el-icon-caret-bottom el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item :command="0">
                                    All
                                </el-dropdown-item>
                                <el-dropdown-item v-for="(item,index) in category" :key="index" :command="item.id">
                                    {{item.name}}
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </div>
                <div class="close-box">
                    <img class="nav-close" src="@/assets/images/index/nav-close.png" @click="showMenu = false" />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                navList: [
                    {
                        name: 'Home',
                        url: '/'
                    },
                    {
                        name: 'Products',
                        url: ''
                    },
                    {
                        name: 'All Products',
                        url: '/product?category_id=0'
                    },
                    {
                        name: 'Hot Sales',
                        url: '/product?category_id=hot'
                    },
                    // {
                    //     name: 'My Favorites',
                    //     url: '/MyFavorites'
                    // },
                    // {
                    //     name: 'My Orders',
                    //     url: '/MyOrders'
                    // },
                    {
                        name: 'Contact Us',
                        url: '/LeaveMessage'
                    }
                ],
                showMenu: false
            }
        },
        created() {
            this.$store.dispatch('getCategory')
        },
        computed: {
            category() {
                return this.$store.state.category
            }
        },
        methods: {
            to(path) {
                this.showMenu = false
                this.$router.push({
                    path
                })
            },
            
            toProducts(category_id) {
                this.showMenu = false
                this.$router.push({
                    path: '/product',
                    query: {
                        category_id
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    // PC
    @media only screen and (min-width: 1024px) {
        .page-nav {
            width: 100%;
            
            .logo-box {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                padding: 4rem 0;
                
                .logo {
                    width: 10rem;
                    cursor: pointer;
                }
				
				.system-name {
					font-size: 3rem;
					font-weight: bold;
					color: #000000;
					white-space: nowrap;
					cursor: pointer;
				}
            }
            
            .nav-list {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                width: 100%;
                
                .nav-item {
                    height: 5rem;
                    line-height: 5rem;
                    padding: 0 2.5rem 2rem;
                    font-size: 1.6rem;
                    color: #333333;
                    text-align: center;
                    white-space: nowrap;
                    cursor: pointer;
                    
                    &:hover {
                        color: #d1466e;
                    }
                }
                
                .dropdown-item {
                    height: 5rem;
                    line-height: 5rem;
                    margin: 0 2.5rem 2rem;
                    cursor: pointer;
                    
                    &:hover {
                        color: #d1466e;
                    }
                    
                    .dropdown-title {
                        font-size: 1.6rem;
                        color: #333333;
                        text-align: center;
                        white-space: nowrap;
                    }
                }
            }
            
            .menu-box {
                display: none;
            }
            
            .nav-mask {
                display: none;
            }
        }
    }
    
    // 手机
    @media only screen and (max-width: 1024px) {
        .page-nav {
            width: 100%;
            
            .logo-box {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                box-sizing: border-box;
                padding: $h5-box-padding;
                
                .logo {
                    width: 7.5rem;
                    cursor: pointer;
                }
				
				.system-name {
					font-size: 2rem;
					font-weight: bold;
					color: #000000;
					white-space: nowrap;
					cursor: pointer;
				}
            }
            
            .nav-list {
                display: none;
            }
            
            .menu-box {
                display: flex;
                align-items: center;
                width: 100%;
                height: 5.5rem;
                box-sizing: border-box;
                padding: 0 $h5-box-padding;
                border-top: 1px solid #e9e9e9;
                
                .menu {
                    display: flex;
                    align-items: center;
                    max-width: 100%;
                    font-size: 1.8rem;
                    color: #333333;
                    cursor: pointer;
                    
                    .icon {
                        margin-right: 6px;
                        min-width: 2.8rem;
                        max-width: 2.8rem;
                    }
                }
            }
            
            .nav-mask {
                position: fixed;
                top: 0;
                left: 0;
                z-index: 99;
                display: flex;
                width: 100%;
                height: 100vh;
                background-color: rgba(0, 0, 0, 0.5);
                
                .nav-list {
                    display: flex;
                    flex-direction: column;
                    width: calc(100% - 7rem);
                    height: 100vh;
                    background-color: #FFFFFF;
                    
                    .nav-item {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        height: 5.5rem;
                        box-sizing: border-box;
                        padding: 0 $h5-box-padding;
                        font-size: 1.6rem;
                        color: #333333;
                        word-wrap: break-word;
                        cursor: pointer;
                        
                        &:hover {
                            color: #d1466e;
                        }
                        
                        &:not(:last-child) {
                            border-bottom: 1px solid #e9e9e9;
                        }
                    }
                    
                    .dropdown-item {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        height: 5.5rem;
                        box-sizing: border-box;
                        padding: 0 $h5-box-padding;
                        border-bottom: 1px solid #e9e9e9;
                        cursor: pointer;
                        
                        &:hover {
                            color: #d1466e;
                        }
                        
                        .dropdown-title {
                            width: 100%;
                            font-size: 1.6rem;
                            color: #333333;
                            word-wrap: break-word;
                        }
                    }
                }
                
                .close-box {
                    display: flex;
                    justify-content: center;
                    width: 7rem;
                    box-sizing: border-box;
                    padding: 2rem 0 0;
                    
                    .nav-close {
                        min-width: 3.6rem;
                        max-width: 3.6rem;
                        height: 3.6rem;
                    }
                }
            }
        }
    }
</style>